<template>
    <div class="children-attribute">
        <img src="../../../assets/imgs/mobile/children/img_shenz@2x.png" alt="兒童身高、體重、頭圍、胸圍介紹">
        <ul class="children-attribute-tabs">
            <router-link to="/children/height" class="children-attribute-tab">身高</router-link>
            <router-link to="/children/weight" class="children-attribute-tab current">體重</router-link>
            <router-link to="/children/bmi" class="children-attribute-tab">BMI</router-link>
            <router-link to="/children/head_circ" class="children-attribute-tab">頭圍</router-link>
            <router-link to="/children/chest_circ" class="children-attribute-tab">胸圍</router-link>
        </ul>
        <div class="children-attribute-content">
            <div style="width: 0px;height: 0px; position: relative; overflow: hidden;"><h1>小孩(兒童)體重正常值是多少？</h1></div>
            <p>體重是反映小兒生長發育的最重要也是最靈敏的指標。因為體重反映孩子的營養狀況，尤其是近期的營養狀況。體重可以受多種因素如:營養、輔食添加、疾病等的影響。體重在出生頭3個月增長最快，一般為月增長600～1000克，最好不低於600克。3～6個月次之，一般月增長60 0～800克。6～12個月平均每個月增長300克。1歲後小兒生長速度明顯減慢，1～3歲小兒平均每個月體重增長150克。1歲後小兒的體重可用下列公式計算:體重（kg）=年齡（歲）×2+7（或8）</p>
        </div>

        <div class="chart">
            <div class="title">0～18歲男孩體重參照值(kg)</div>
            <div class="table">
                <div class="head">
                    <div class="column">年齡</div><div class="column">下等</div><div class="column">中下等</div><div class="column">均值</div><div class="column">中上等</div><div class="column">上等</div>
                </div>
                <div class="content">
                    <div class="row"><div class="column">1月～</div><div class="column">3.84</div><div class="column">4.47</div><div class="column">5.1</div><div class="column">5.73</div><div class="column">6.36</div></div>
                    <div class="row"><div class="column">初生</div><div class="column">2.54</div><div class="column">2.92</div><div class="column">3.3</div><div class="column">3.68</div><div class="column">4.06</div></div>
                    <div class="row"><div class="column">2月～</div><div class="column">4.72</div><div class="column">5.44</div><div class="column">6.16</div><div class="column">6.88</div><div class="column">7.6</div></div>
                    <div class="row"><div class="column">3月～</div><div class="column">5.4</div><div class="column">6.19</div><div class="column">6.98</div><div class="column">7.77</div><div class="column">8.56</div></div>
                    <div class="row"><div class="column">4月～</div><div class="column">5.94</div><div class="column">6.75</div><div class="column">7.56</div><div class="column">8.37</div><div class="column">9.18</div></div>
                    <div class="row"><div class="column">5月～</div><div class="column">6.26</div><div class="column">7.14</div><div class="column">8.02</div><div class="column">8.9</div><div class="column">9.78</div></div>
                    <div class="row"><div class="column">6月～</div><div class="column">6.74</div><div class="column">7.68</div><div class="column">8.62</div><div class="column">9.56</div><div class="column">10.5</div></div>
                    <div class="row"><div class="column">8月～</div><div class="column">7.19</div><div class="column">8.19</div><div class="column">9.19</div><div class="column">10.19</div><div class="column">11.19</div></div>
                    <div class="row"><div class="column">10月～</div><div class="column">7.57</div><div class="column">8.61</div><div class="column">9.65</div><div class="column">10.69</div><div class="column">11.73</div></div>
                    <div class="row"><div class="column">12月～</div><div class="column">8.08</div><div class="column">9.12</div><div class="column">10.16</div><div class="column">11.2</div><div class="column">12.24</div></div>
                    <div class="row"><div class="column">15月～</div><div class="column">8.48</div><div class="column">9.59</div><div class="column">10.7</div><div class="column">11.81</div><div class="column">12.92</div></div>
                    <div class="row"><div class="column">18月～</div><div class="column">8.87</div><div class="column">10.06</div><div class="column">11.25</div><div class="column">12.44</div><div class="column">13.63</div></div>
                    <div class="row"><div class="column">21月～</div><div class="column">9.31</div><div class="column">10.57</div><div class="column">11.83</div><div class="column">13.09</div><div class="column">14.35</div></div>
                    <div class="row"><div class="column">2.0歲～</div><div class="column">10.01</div><div class="column">11.29</div><div class="column">12.57</div><div class="column">13.85</div><div class="column">15.13</div></div>
                    <div class="row"><div class="column">2.5歲～</div><div class="column">10.9</div><div class="column">12.23</div><div class="column">13.56</div><div class="column">14.89</div><div class="column">16.22</div></div>
                    <div class="row"><div class="column">3.0歲～</div><div class="column">11.4</div><div class="column">12.91</div><div class="column">14.42</div><div class="column">15.93</div><div class="column">17.44</div></div>
                    <div class="row"><div class="column">3.5歲～</div><div class="column">12.27</div><div class="column">13.82</div><div class="column">15.37</div><div class="column">16.92</div><div class="column">18.47</div></div>
                    <div class="row"><div class="column">4.0歲～</div><div class="column">12.69</div><div class="column">14.46</div><div class="column">16.23</div><div class="column">18</div><div class="column">19.77</div></div>
                    <div class="row"><div class="column">4.5歲～</div><div class="column">13.36</div><div class="column">15.3</div><div class="column">17.24</div><div class="column">19.18</div><div class="column">21.12</div></div>
                    <div class="row"><div class="column">5.0歲～</div><div class="column">14.08</div><div class="column">16.21</div><div class="column">18.34</div><div class="column">20.47</div><div class="column">22.6</div></div>
                    <div class="row"><div class="column">5.5歲～</div><div class="column">14.88</div><div class="column">17.13</div><div class="column">19.38</div><div class="column">21.63</div><div class="column">23.88</div></div>
                    <div class="row"><div class="column">6.0歲～</div><div class="column">15.77</div><div class="column">18.37</div><div class="column">20.97</div><div class="column">23.57</div><div class="column">26.17</div></div>
                    <div class="row"><div class="column">7.0歲～</div><div class="column">15.25</div><div class="column">19.3</div><div class="column">23.35</div><div class="column">27.4</div><div class="column">31.45</div></div>
                    <div class="row"><div class="column">8.0歲～</div><div class="column">16.21</div><div class="column">20.97</div><div class="column">25.73</div><div class="column">30.49</div><div class="column">35.25</div></div>
                    <div class="row"><div class="column">9.0歲～</div><div class="column">17.22</div><div class="column">22.94</div><div class="column">28.66</div><div class="column">34.38</div><div class="column">40.1</div></div>
                    <div class="row"><div class="column">10歲～</div><div class="column">18.42</div><div class="column">25.15</div><div class="column">31.88</div><div class="column">38.61</div><div class="column">45.34</div></div>
                    <div class="row"><div class="column">11歲～</div><div class="column">20.15</div><div class="column">27.92</div><div class="column">35.69</div><div class="column">43.46</div><div class="column">51.23</div></div>
                    <div class="row"><div class="column">12歲～</div><div class="column">22.28</div><div class="column">31.01</div><div class="column">39.74</div><div class="column">48.47</div><div class="column">57.2</div></div>
                    <div class="row"><div class="column">13歲～</div><div class="column">27.06</div><div class="column">36.51</div><div class="column">45.96</div><div class="column">55.41</div><div class="column">64.86</div></div>
                    <div class="row"><div class="column">14歲～</div><div class="column">31.23</div><div class="column">41.03</div><div class="column">50.83</div><div class="column">60.63</div><div class="column">70.43</div></div>
                    <div class="row"><div class="column">15歲～</div><div class="column">36.33</div><div class="column">45.22</div><div class="column">54.11</div><div class="column">63</div><div class="column">71.89</div></div>
                    <div class="row"><div class="column">16歲～</div><div class="column">39.34</div><div class="column">48.07</div><div class="column">56.8</div><div class="column">65.53</div><div class="column">74.26</div></div>
                    <div class="row"><div class="column">17～18歲</div><div class="column">41.33</div><div class="column">49.79</div><div class="column">58.25</div><div class="column">66.71</div><div class="column">75.17</div></div>
                </div>
            </div>
        </div>
        <div class="chart">
            <div class="title">0～18歲女孩體重參照值(kg)</div>
            <div class="table">
                <div class="head">
                    <div class="column">年齡</div><div class="column">下等</div><div class="column">中下等</div><div class="column">均值</div><div class="column">中上等</div><div class="column">上等</div>
                </div>
                <div class="content">
                    <div class="row"><div class="column">初生</div><div class="column">2.48</div><div class="column">2.84</div><div class="column">3.2</div><div class="column">3.56</div><div class="column">3.92</div></div>
                    <div class="row"><div class="column">1月～</div><div class="column">3.67</div><div class="column">4.24</div><div class="column">4.81</div><div class="column">5.38</div><div class="column">5.95</div></div>
                    <div class="row"><div class="column">2月～</div><div class="column">4.44</div><div class="column">5.09</div><div class="column">5.74</div><div class="column">6.39</div><div class="column">7.04</div></div>
                    <div class="row"><div class="column">3月～</div><div class="column">5.02</div><div class="column">5.72</div><div class="column">6.42</div><div class="column">7.12</div><div class="column">7.82</div></div>
                    <div class="row"><div class="column">4月～</div><div class="column">5.51</div><div class="column">6.26</div><div class="column">7.01</div><div class="column">7.76</div><div class="column">8.51</div></div>
                    <div class="row"><div class="column">5月～</div><div class="column">5.99</div><div class="column">6.76</div><div class="column">7.53</div><div class="column">8.3</div><div class="column">9.07</div></div>
                    <div class="row"><div class="column">6月～</div><div class="column">6.2</div><div class="column">7.1</div><div class="column">8</div><div class="column">8.9</div><div class="column">9.8</div></div>
                    <div class="row"><div class="column">8月～</div><div class="column">6.71</div><div class="column">7.68</div><div class="column">8.65</div><div class="column">9.62</div><div class="column">10.59</div></div>
                    <div class="row"><div class="column">10月～</div><div class="column">7.11</div><div class="column">8.1</div><div class="column">9.09</div><div class="column">10.08</div><div class="column">11.07</div></div>
                    <div class="row"><div class="column">12月～</div><div class="column">7.42</div><div class="column">8.47</div><div class="column">9.52</div><div class="column">10.57</div><div class="column">11.62</div></div>
                    <div class="row"><div class="column">15月～</div><div class="column">7.99</div><div class="column">9.04</div><div class="column">10.09</div><div class="column">11.14</div><div class="column">12.19</div></div>
                    <div class="row"><div class="column">18月～</div><div class="column">8.43</div><div class="column">9.54</div><div class="column">10.65</div><div class="column">11.76</div><div class="column">12.87</div></div>
                    <div class="row"><div class="column">21月～</div><div class="column">9.01</div><div class="column">10.13</div><div class="column">11.25</div><div class="column">12.37</div><div class="column">13.49</div></div>
                    <div class="row"><div class="column">2.0歲～</div><div class="column">9.58</div><div class="column">10.81</div><div class="column">12.04</div><div class="column">13.27</div><div class="column">14.5</div></div>
                    <div class="row"><div class="column">2.5歲～</div><div class="column">10.31</div><div class="column">11.64</div><div class="column">12.97</div><div class="column">14.3</div><div class="column">15.63</div></div>
                    <div class="row"><div class="column">3.0歲～</div><div class="column">11.15</div><div class="column">12.58</div><div class="column">14.01</div><div class="column">15.44</div><div class="column">16.87</div></div>
                    <div class="row"><div class="column">3.5歲～</div><div class="column">11.9</div><div class="column">13.42</div><div class="column">14.94</div><div class="column">16.46</div><div class="column">17.98</div></div>
                    <div class="row"><div class="column">4.0歲～</div><div class="column">12.45</div><div class="column">14.13</div><div class="column">15.81</div><div class="column">17.49</div><div class="column">19.17</div></div>
                    <div class="row"><div class="column">4.5歲～</div><div class="column">13.04</div><div class="column">14.92</div><div class="column">16.8</div><div class="column">18.68</div><div class="column">20.56</div></div>
                    <div class="row"><div class="column">5.0歲～</div><div class="column">13.9</div><div class="column">15.87</div><div class="column">17.84</div><div class="column">19.81</div><div class="column">21.78</div></div>
                    <div class="row"><div class="column">5.5歲～</div><div class="column">14.36</div><div class="column">16.58</div><div class="column">18.8</div><div class="column">21.02</div><div class="column">23.24</div></div>
                    <div class="row"><div class="column">6.0歲～</div><div class="column">15.26</div><div class="column">17.81</div><div class="column">20.36</div><div class="column">22.91</div><div class="column">25.46</div></div>
                    <div class="row"><div class="column">7.0歲～</div><div class="column">15.08</div><div class="column">18.7</div><div class="column">22.32</div><div class="column">25.94</div><div class="column">29.56</div></div>
                    <div class="row"><div class="column">8.0歲～</div><div class="column">16.12</div><div class="column">20.35</div><div class="column">24.58</div><div class="column">28.81</div><div class="column">33.04</div></div>
                    <div class="row"><div class="column">9.0歲～</div><div class="column">17.33</div><div class="column">22.39</div><div class="column">27.45</div><div class="column">32.51</div><div class="column">37.57</div></div>
                    <div class="row"><div class="column">10歲～</div><div class="column">18.69</div><div class="column">24.9</div><div class="column">31.11</div><div class="column">37.32</div><div class="column">43.53</div></div>
                    <div class="row"><div class="column">11歲～</div><div class="column">21.12</div><div class="column">28.44</div><div class="column">35.76</div><div class="column">43.08</div><div class="column">50.4</div></div>
                    <div class="row"><div class="column">12歲～</div><div class="column">24.66</div><div class="column">32.42</div><div class="column">40.18</div><div class="column">47.94</div><div class="column">55.7</div></div>
                    <div class="row"><div class="column">13歲～</div><div class="column">28.93</div><div class="column">36.69</div><div class="column">44.45</div><div class="column">52.21</div><div class="column">59.97</div></div>
                    <div class="row"><div class="column">14歲～</div><div class="column">32.51</div><div class="column">39.62</div><div class="column">46.73</div><div class="column">53.84</div><div class="column">60.95</div></div>
                    <div class="row"><div class="column">15歲～</div><div class="column">34.6</div><div class="column">61.65</div><div class="column">48.7</div><div class="column">55.75</div><div class="column">62.8</div></div>
                    <div class="row"><div class="column">16歲～</div><div class="column">36.41</div><div class="column">43.19</div><div class="column">49.97</div><div class="column">56.75</div><div class="column">63.53</div></div>
                    <div class="row"><div class="column">17～18歲</div><div class="column">37.19</div><div class="column">43.78</div><div class="column">50.37</div><div class="column">56.96</div><div class="column">63.55</div></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
        created: function(){
            setTimeout(() => {
                window.scrollTo(0, 860)    
            }, 0);
        }
    }
</script>

<style lang="scss" scoped>
    .children-attribute {
        margin: 0px 24px;
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: #fff;
    }

    .children-attribute > img {
        display: block;
        width: 440px;
        height: 468px;
        margin: 40px 0px 0px 32px;
    }

    .children-attribute > .children-attribute-tabs {
        margin-top: 46px;
        height: 48pxx;
        display: block;
        display: flex;
        flex-direction: row;
        margin-left: 32px;
        justify-content: flex-start;
    }

    .children-attribute > .children-attribute-tabs > .children-attribute-tab {
        font-size: 34px;
        line-height: 33px;
        height: 48px;
        color: #231F20;
        font-weight: bold;
        display: inline-block;
        margin-right: 52px;
        cursor: pointer;
    }

    .children-attribute > .children-attribute-tabs > .children-attribute-tab.current {
        color: #36C4D0;
        position: relative;
    }

    .children-attribute > .children-attribute-tabs > .children-attribute-tab.current::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0px;
        height: 4px;
        width: 12px;
        background-color: #36C4D0;
        transform: translateX(-50%);
    }

    .children-attribute > .children-attribute-content {
        background-color: #fff;
        font-size: 27.54px;
        line-height: 41.32px;
        font-weight: 400;
        color: #636363;
        margin: 12px 32px;
    }

    
    .chart {
        margin-bottom: 20px;
    }

    .chart,
    .chart > .table {
        display: flex;
        flex-direction: column;
    }

    .chart > .title {
        height: 77px;
        line-height: 77px;
        color: #242020;
        font-size: 30px;
        font-weight: bold;
        position: relative;
        padding-left: 48px;
        margin-left: 32px;
        box-sizing: border-box;
    }

    .chart > .title::after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 32px;
        height: 32px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url(../../../assets/imgs/pc/children/img_copy@2x.png);;
    }

    .chart > .table > .content > .row,
    .chart > .table > .head {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        background-color: #EEF9FA;
        height: 50px;
        align-items: center;
    }

    .chart > .table > .head {
        border-bottom: 1px solid #CEE5E7;
    }

    .chart:last-child {
        margin-bottom: 70px;
    }
    
    .chart:last-child > .table > .head {
        background-color: #FFE7E7;
        border-bottom: 1px solid #E8C5C5;
    }


    .chart > .table > .content > .row {
        background-color: #fff;
    }

    .chart > .table > .content > .row:nth-child(odd) {
        background-color: #F6F7F8;
    }

    .chart > .table > .head > .column,
    .chart > .table > .content > .row > .column {
        width: 100%;
        text-align: center;
        font-size: 30px;
        font-weight: 400;
        color: #646464;
    }

    .chart > .table > .head > .column {
        color: #242020;
        font-size: 28px;
        font-weight: bold;
    }
</style>
